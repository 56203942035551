import _utils from "./utils.cjs";
var exports = {};
const utils = _utils;

function cleanRaw(node, item, list) {
  // raw in stylesheet or block children
  if (utils.isNodeChildrenList(this.stylesheet, list) || utils.isNodeChildrenList(this.block, list)) {
    list.remove(item);
  }
}

exports = cleanRaw;
export default exports;