import * as _cssTree2 from "css-tree";

var _cssTree = "default" in _cssTree2 ? _cssTree2.default : _cssTree2;

import _Atrule from "./Atrule.cjs";
import _AttributeSelector from "./AttributeSelector.cjs";
import _Value from "./Value.cjs";
import _Dimension from "./Dimension.cjs";
import _Percentage from "./Percentage.cjs";
import _Number2 from "./Number.cjs";
import _Url from "./Url.cjs";
import _color from "./color.cjs";
var exports = {};
const cssTree = _cssTree;
const Atrule = _Atrule;
const AttributeSelector = _AttributeSelector;
const Value = _Value;
const Dimension = _Dimension;
const Percentage = _Percentage;
const _Number = _Number2;
const Url = _Url;
const color = _color;
const handlers = {
  Atrule,
  AttributeSelector,
  Value,
  Dimension,
  Percentage,
  Number: _Number.Number,
  Url,
  Hash: color.compressHex,
  Identifier: color.compressIdent,
  Function: color.compressFunction
};

function replace(ast) {
  cssTree.walk(ast, {
    leave(node, item, list) {
      if (handlers.hasOwnProperty(node.type)) {
        handlers[node.type].call(this, node, item, list);
      }
    }

  });
}

exports = replace;
export default exports;