import _index from "./prepare/index.cjs";
import _mergeAtrule from "./1-mergeAtrule.cjs";
import _initialMergeRuleset from "./2-initialMergeRuleset.cjs";
import _disjoinRuleset from "./3-disjoinRuleset.cjs";
import _restructShorthand from "./4-restructShorthand.cjs";
import _restructBlock from "./6-restructBlock.cjs";
import _mergeRuleset from "./7-mergeRuleset.cjs";
import _restructRuleset from "./8-restructRuleset.cjs";
var exports = {};
const index = _index;
const _1MergeAtrule = _mergeAtrule;
const _2InitialMergeRuleset = _initialMergeRuleset;
const _3DisjoinRuleset = _disjoinRuleset;
const _4RestructShorthand = _restructShorthand;
const _6RestructBlock = _restructBlock;
const _7MergeRuleset = _mergeRuleset;
const _8RestructRuleset = _restructRuleset;

function restructure(ast, options) {
  // prepare ast for restructing
  const indexer = index(ast, options);
  options.logger("prepare", ast);

  _1MergeAtrule(ast, options);

  options.logger("mergeAtrule", ast);

  _2InitialMergeRuleset(ast);

  options.logger("initialMergeRuleset", ast);

  _3DisjoinRuleset(ast);

  options.logger("disjoinRuleset", ast);

  _4RestructShorthand(ast, indexer);

  options.logger("restructShorthand", ast);

  _6RestructBlock(ast);

  options.logger("restructBlock", ast);

  _7MergeRuleset(ast);

  options.logger("mergeRuleset", ast);

  _8RestructRuleset(ast);

  options.logger("restructRuleset", ast);
}

exports = restructure;
export default exports;