import * as _cssTree2 from "css-tree";

var _cssTree = "default" in _cssTree2 ? _cssTree2.default : _cssTree2;

import _Atrule from "./Atrule.cjs";
import _Comment from "./Comment.cjs";
import _Declaration from "./Declaration.cjs";
import _Raw from "./Raw.cjs";
import _Rule from "./Rule.cjs";
import _TypeSelector from "./TypeSelector.cjs";
import _WhiteSpace from "./WhiteSpace.cjs";
var exports = {};
const cssTree = _cssTree;
const Atrule = _Atrule;
const Comment = _Comment;
const Declaration = _Declaration;
const Raw = _Raw;
const Rule = _Rule;
const TypeSelector = _TypeSelector;
const WhiteSpace = _WhiteSpace;
const handlers = {
  Atrule,
  Comment,
  Declaration,
  Raw,
  Rule,
  TypeSelector,
  WhiteSpace
};

function clean(ast, options) {
  cssTree.walk(ast, {
    leave(node, item, list) {
      if (handlers.hasOwnProperty(node.type)) {
        handlers[node.type].call(this, node, item, list, options);
      }
    }

  });
}

exports = clean;
export default exports;