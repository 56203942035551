import * as _cssTree2 from "css-tree";

var _cssTree = "default" in _cssTree2 ? _cssTree2.default : _cssTree2;

import _keyframes from "./atrule/keyframes.cjs";
var exports = {};
const cssTree = _cssTree;
const keyframes = _keyframes;

function Atrule(node) {
  // compress @keyframe selectors
  if (cssTree.keyword(node.name).basename === "keyframes") {
    keyframes(node);
  }
}

exports = Atrule;
export default exports;