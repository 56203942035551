import * as _cssTree2 from "css-tree";

var _cssTree = "default" in _cssTree2 ? _cssTree2.default : _cssTree2;

import _compress from "./compress.cjs";
import _specificity from "./restructure/prepare/specificity.cjs";
var exports = {};
const cssTree = _cssTree;
const compress = _compress;
const specificity = _specificity;

function encodeString(value) {
  const stringApostrophe = cssTree.string.encode(value, true);
  const stringQuote = cssTree.string.encode(value);
  return stringApostrophe.length < stringQuote.length ? stringApostrophe : stringQuote;
}

const {
  lexer,
  tokenize,
  parse,
  generate,
  walk,
  find,
  findLast,
  findAll,
  fromPlainObject,
  toPlainObject
} = cssTree.fork({
  node: {
    String: {
      generate(node) {
        this.token(cssTree.tokenTypes.String, encodeString(node.value));
      }

    },
    Url: {
      generate(node) {
        const encodedUrl = cssTree.url.encode(node.value);
        const string = encodeString(node.value);
        this.token(cssTree.tokenTypes.Url, encodedUrl.length <= string.length + 5
        /* "url()".length */
        ? encodedUrl : "url(" + string + ")");
      }

    }
  }
});
exports.compress = compress;
exports.specificity = specificity;
exports.find = find;
exports.findAll = findAll;
exports.findLast = findLast;
exports.fromPlainObject = fromPlainObject;
exports.generate = generate;
exports.lexer = lexer;
exports.parse = parse;
exports.toPlainObject = toPlainObject;
exports.tokenize = tokenize;
exports.walk = walk;
export default exports;
const _compress2 = exports.compress,
      _specificity2 = exports.specificity,
      _find = exports.find,
      _findAll = exports.findAll,
      _findLast = exports.findLast,
      _fromPlainObject = exports.fromPlainObject,
      _generate = exports.generate,
      _lexer = exports.lexer,
      _parse = exports.parse,
      _toPlainObject = exports.toPlainObject,
      _tokenize = exports.tokenize,
      _walk = exports.walk;
export { _compress2 as compress, _specificity2 as specificity, _find as find, _findAll as findAll, _findLast as findLast, _fromPlainObject as fromPlainObject, _generate as generate, _lexer as lexer, _parse as parse, _toPlainObject as toPlainObject, _tokenize as tokenize, _walk as walk };