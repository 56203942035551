import * as _cssTree2 from "css-tree";

var _cssTree = "default" in _cssTree2 ? _cssTree2.default : _cssTree2;

var exports = {};
const cssTree = _cssTree;

function cleanDeclartion(node, item, list) {
  if (node.value.children && node.value.children.isEmpty) {
    list.remove(item);
    return;
  }

  if (cssTree.property(node.property).custom) {
    if (/\S/.test(node.value.value)) {
      node.value.value = node.value.value.trim();
    }
  }
}

exports = cleanDeclartion;
export default exports;