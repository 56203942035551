var exports = {};

function compressKeyframes(node) {
  node.block.children.forEach(rule => {
    rule.prelude.children.forEach(simpleselector => {
      simpleselector.children.forEach((data, item) => {
        if (data.type === "Percentage" && data.value === "100") {
          item.data = {
            type: "TypeSelector",
            loc: data.loc,
            name: "to"
          };
        } else if (data.type === "TypeSelector" && data.name === "from") {
          item.data = {
            type: "Percentage",
            loc: data.loc,
            value: "0"
          };
        }
      });
    });
  });
}

exports = compressKeyframes;
export default exports;