import * as _cssTree2 from "css-tree";

var _cssTree = "default" in _cssTree2 ? _cssTree2.default : _cssTree2;

var exports = {};
const cssTree = _cssTree;

function compressBackground(node) {
  function flush() {
    if (!buffer.length) {
      buffer.unshift({
        type: "Number",
        loc: null,
        value: "0"
      }, {
        type: "Number",
        loc: null,
        value: "0"
      });
    }

    newValue.push.apply(newValue, buffer);
    buffer = [];
  }

  let newValue = [];
  let buffer = [];
  node.children.forEach(node => {
    if (node.type === "Operator" && node.value === ",") {
      flush();
      newValue.push(node);
      return;
    } // remove defaults


    if (node.type === "Identifier") {
      if (node.name === "transparent" || node.name === "none" || node.name === "repeat" || node.name === "scroll") {
        return;
      }
    }

    buffer.push(node);
  });
  flush();
  node.children = new cssTree.List().fromArray(newValue);
}

exports = compressBackground;
export default exports;