import * as _cssTree2 from "css-tree";

var _cssTree = "default" in _cssTree2 ? _cssTree2.default : _cssTree2;

import _font from "./property/font.cjs";
import _fontWeight from "./property/font-weight.cjs";
import _background from "./property/background.cjs";
import _border from "./property/border.cjs";
var exports = {};
const cssTree = _cssTree;
const font = _font;
const fontWeight = _fontWeight;
const background = _background;
const border = _border;
const handlers = {
  "font": font,
  "font-weight": fontWeight,
  "background": background,
  "border": border,
  "outline": border
};

function compressValue(node) {
  if (!this.declaration) {
    return;
  }

  const property = cssTree.property(this.declaration.property);

  if (handlers.hasOwnProperty(property.basename)) {
    handlers[property.basename](node);
  }
}

exports = compressValue;
export default exports;