import * as _cssTree2 from "css-tree";

var _cssTree = "default" in _cssTree2 ? _cssTree2.default : _cssTree2;

import _utils from "./utils.cjs";
var exports = {};
const cssTree = _cssTree;
const utils = _utils;

function processRule(node, item, list) {
  const selectors = node.prelude.children;
  const declarations = node.block.children;
  list.prevUntil(item.prev, function (prev) {
    // skip non-ruleset node if safe
    if (prev.type !== "Rule") {
      return utils.unsafeToSkipNode.call(selectors, prev);
    }

    const prevSelectors = prev.prelude.children;
    const prevDeclarations = prev.block.children; // try to join rulesets with equal pseudo signature

    if (node.pseudoSignature === prev.pseudoSignature) {
      // try to join by selectors
      if (utils.isEqualSelectors(prevSelectors, selectors)) {
        prevDeclarations.appendList(declarations);
        list.remove(item);
        return true;
      } // try to join by declarations


      if (utils.isEqualDeclarations(declarations, prevDeclarations)) {
        utils.addSelectors(prevSelectors, selectors);
        list.remove(item);
        return true;
      }
    } // go to prev ruleset if has no selector similarities


    return utils.hasSimilarSelectors(selectors, prevSelectors);
  });
} // NOTE: direction should be left to right, since rulesets merge to left
// ruleset. When direction right to left unmerged rulesets may prevent lookup
// TODO: remove initial merge


function initialMergeRule(ast) {
  cssTree.walk(ast, {
    visit: "Rule",
    enter: processRule
  });
}

exports = initialMergeRule;
export default exports;