import * as _cssTree2 from "css-tree";

var _cssTree = "default" in _cssTree2 ? _cssTree2.default : _cssTree2;

var exports = {};
const cssTree = _cssTree;

class Index {
  constructor() {
    this.map = new Map();
  }

  resolve(str) {
    let index = this.map.get(str);

    if (index === undefined) {
      index = this.map.size + 1;
      this.map.set(str, index);
    }

    return index;
  }

}

function createDeclarationIndexer() {
  const ids = new Index();
  return function markDeclaration(node) {
    const id = cssTree.generate(node);
    node.id = ids.resolve(id);
    node.length = id.length;
    node.fingerprint = null;
    return node;
  };
}

exports = createDeclarationIndexer;
export default exports;